<script>
import { mapGetters } from "vuex";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import OrganizationChart from "vue-organization-chart";
import Departments from "./departments.vue";
import JobTitles from "./job-titles.vue";
import Employees from "./employees.vue";
import {
  departmentMethods,
  employeeMethods,
  jobTitleMethods,
  orgMethods,
  workPlanMethods,
} from "../../../state/helpers";

/**
 * Team component
 */
export default {
  page: {
    title: "Team",
  },
  components: {
    Layout,
    PageHeader,
    OrganizationChart,
    Departments,
    JobTitles,
    Employees,
  },
  data() {
    return {
      title: "My Team",
      items: [
        {
          text: "HR",
          href: "/",
        },
        {
          text: "Team",
          active: true,
        },
      ],
    };
  },
  watch: {
    employees: {
      immediate: true,
      handler(_, oldVal) {
        if (!oldVal) this.getEmployees();
      },
    },
    departments: {
      immediate: true,
      handler(_, oldVal) {
        if (!oldVal) this.getDepartments();
      },
    },
    job_titles: {
      immediate: true,
      handler(_, oldVal) {
        if (!oldVal) this.getJobTitles();
      },
    },
    work_plans: {
      immediate: true,
      handler(_, oldVal) {
        if (!oldVal) this.getWorkPlans();
      },
    },
    organization_tree: {
      immediate: true,
      handler(_, oldVal) {
        if (!oldVal) this.getTree();
      },
    },
  },
  computed: {
    ...mapGetters("employees", ["employees"]),
    ...mapGetters("departments", ["departments"]),
    ...mapGetters("jobTitles", ["job_titles"]),
    ...mapGetters("workPlans", ["work_plans"]),
    ...mapGetters("organizations", ["organization_tree"]),
    currentUser() {
      return this.$store.state.auth?.currentUser?.user;
    },
  },
  mounted() {},
  methods: {
    ...employeeMethods,
    ...departmentMethods,
    ...jobTitleMethods,
    ...workPlanMethods,
    ...orgMethods,
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <b-tabs content-class="mt-3" fill pills>
      <b-tab title="Employees" active>
        <Employees
          :departments="departments"
          :employees="employees"
          :job_titles="job_titles"
          :work_plans="work_plans"
        />
      </b-tab>
      <b-tab
        v-if="currentUser?.name !== 'employee'"
        title="Organization Chart"
      >
        <b-card>
          <OrganizationChart
            :pan="false"
            :zoom="false"
            :datasource="organization_tree"
          />
        </b-card>
      </b-tab>
      <b-tab v-if="currentUser?.name !== 'employee'" title="Departments">
        <Departments :employees="employees" :departments="departments" />
      </b-tab>
      <b-tab v-if="currentUser?.name !== 'employee'" title="Job Titles">
        <JobTitles :job_titles="job_titles" />
      </b-tab>
    </b-tabs>
  </Layout>
</template>