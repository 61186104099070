<script>
import { required, numeric } from "vuelidate/lib/validators";
import { jobTitleMethods } from "../../../state/helpers";
import ConfirmDelete from "@/components/confirm-delete";

/**
 * Job Titles component
 */
export default {
  props: {
    job_titles: {
      default: [],
      required: true,
    },
  },
  components: {
    ConfirmDelete,
  },
  data() {
    return {
      submitted: false,
      job_title: {},
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: false,
      fields: [
        { key: "name", sortable: true },
        { key: "min_salary", sortable: true },
        { key: "max_salary", sortable: true },
        { key: "actions", sortable: true },
      ],
    };
  },
  validations: {
    job_title: {
      name: { required },
      min_salary: { required, numeric },
      max_salary: { required, numeric },
    },
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.job_titles.length;
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.job_titles.length;
  },
  methods: {
    ...jobTitleMethods,
    async formSubmit(e) {
      e.preventDefault();
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) return;
      const newJobTitle = {
        id: this.job_title.id ?? null,
        name: this.job_title.name,
        min_salary: this.job_title.min_salary,
        max_salary: this.job_title.max_salary,
        note: this.job_title.note ?? null,
      };
      const res = await this.createJobTitle(newJobTitle);
      if (res.success) {
        this.$v.$reset();
        this.job_title = {};
        this.$bvModal.hide("job-titles-modal");
      }
    },
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    onRowSelected(row) {
      const selectedJobTitle = row.item;
      this.job_title = {
        ...selectedJobTitle,
      };
      console.log(this.job_title, selectedJobTitle);
      this.$bvModal.show("job-titles-modal");
    },
  },
};
</script>

<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <b-button
            v-b-modal.job-titles-modal
            variant="success"
            size="sm"
            class="float-right mb-2"
          >
            <i class="mdi mdi-plus"></i>
          </b-button>
          <div class="row">
            <div class="col-sm-12 col-md-6">
              <div id="tickets-table_length" class="dataTables_length">
                <label class="d-inline-flex align-items-center">
                  Show&nbsp;
                  <b-form-select
                    v-model="perPage"
                    size="sm"
                    :options="pageOptions"
                  ></b-form-select
                  >&nbsp;entries
                </label>
              </div>
            </div>
            <!-- Search -->
            <div class="col-sm-12 col-md-6">
              <div
                id="tickets-table_filter"
                class="dataTables_filter text-md-right"
              >
                <label class="d-inline-flex align-items-center">
                  Search:
                  <b-form-input
                    v-model="filter"
                    type="search"
                    placeholder="Search..."
                    class="form-control form-control-sm ml-2"
                  ></b-form-input>
                </label>
              </div>
            </div>
            <!-- End search -->
          </div>
          <!-- Table -->
          <div class="table-responsive mb-0">
            <b-table
              hover
              striped
              :items="job_titles"
              :fields="fields"
              responsive="sm"
              :per-page="perPage"
              :current-page="currentPage"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
            >
              <template #cell()="row">
                <div class="text-nowrap">
                  {{ row.value || "---" }}
                </div>
              </template>
              <template v-slot:cell(actions)="row">
                <b-button
                  v-b-tooltip.hover
                  data-toggle="tooltip"
                  title="Edit"
                  variant="ghost"
                  @click="onRowSelected(row)"
                >
                  <i class="mdi mdi-pencil font-size-18"></i>
                </b-button>
                <confirm-delete
                  icon
                  :callback="() => deleteJobTitle(row.item.id)"
                />
              </template>
            </b-table>
          </div>
          <div class="row">
            <div class="col">
              <div
                class="dataTables_paginate paging_simple_numbers float-right"
              >
                <ul class="pagination pagination-rounded mb-0">
                  <!-- pagination -->
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="rows"
                    :per-page="perPage"
                  ></b-pagination>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      id="job-titles-modal"
      centered
      title="new Job Title"
      title-class="font-18 text-capitalize"
      hide-footer
    >
      <form
        class="needs-validation form-horizontal"
        role="form"
        @submit.prevent="formSubmit"
      >
        <b-form-group label-cols-lg="4" label="Name" label-for="name">
          <b-form-input
            type="text"
            name="name"
            v-model="job_title.name"
            :class="{
              'is-invalid': submitted && $v.job_title.name.$error,
            }"
          />
          <b-form-feedback>
            <span v-if="!$v.job_title.name.required"
              >This value is required.</span
            >
          </b-form-feedback>
        </b-form-group>
        <b-form-group
          label-cols-lg="4"
          label="Min Salary"
          label-for="min_salary"
        >
          <b-form-input
            type="number"
            name="min_salary"
            v-model="job_title.min_salary"
            :class="{
              'is-invalid': submitted && $v.job_title.min_salary.$error,
            }"
          />
          <b-form-feedback>
            <span v-if="!$v.job_title.min_salary.required"
              >This value is required.</span
            >
          </b-form-feedback>
        </b-form-group>
        <b-form-group
          label-cols-lg="4"
          label="Max Salary"
          label-for="max_salary"
        >
          <b-form-input
            type="number"
            name="max_salary"
            v-model="job_title.max_salary"
            :class="{
              'is-invalid': submitted && $v.job_title.max_salary.$error,
            }"
          />
          <b-form-feedback>
            <span v-if="!$v.job_title.max_salary.required"
              >This value is required.</span
            >
          </b-form-feedback>
        </b-form-group>
        <!-- <b-form-group label-cols-lg="4" label="Note" label-for="note">
          <b-form-textarea name="note" />
        </b-form-group> -->
        <div class="text-right">
          <b-button type="submit" variant="primary">Submit</b-button>
        </div>
      </form>
    </b-modal>
  </div>
</template>