<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import { employeeMethods, userMethods } from "../../../state/helpers";
import { required, email, numeric } from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";
import Switches from "vue-switches";
import ConfirmDelete from "@/components/confirm-delete";

const EMPLOYEE = {
  is_active: true,
  email: "",
  emergency_contact_name: "",
  emergency_contact_number: "",
  address: "",
  join_date: "",
  reporting_to: "",
  department: "",
  job_title: "",
  contract_type: "",
  contract_start: "",
  contract_end: "",
  probation_date: "",
  work_plan_type: "",
  work_plan: "",
  salary_net_salary: "",
  salary_gross: "",
  salary_net: "",
  salary_taxes: "",
  salary_social: "",
  salary_social_employee: "",
  salary_social_company: "",
  user: {
    email: "",
    profile: {
      first_name: "",
      last_name: "",
      other_name: "",
      mobile: "",
      birth_date: "",
      gender: "",
      marital_status: "",
      number_of_kids: "",
      religion: "",
      nationality: "",
    },
  },
};

/**
 * Employees component
 */
export default {
  props: {
    employees: {
      default: [],
      required: true,
    },
    departments: {
      default: [],
      required: true,
    },
    job_titles: {
      default: [],
      required: true,
    },
    work_plans: {
      default: [],
      required: true,
    },
  },
  components: {
    FormWizard,
    TabContent,
    Multiselect,
    Switches,
    ConfirmDelete,
  },
  data() {
    return {
      loading: false,
      updating: [],
      employee: {
        is_active: true,
        salary_net_salary: true,
        user: {
          profile: {},
        },
      },
      title: "Employees",
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: false,
      fields: [
        { key: "id", sortable: true },
        { label: "Active", key: "is_active", sortable: true, filter: true },
        {
          key: "name",
          sortable: true,
        },
        {
          key: "job_title_id",
          label: "Job Title",
          sortable: true,
        },
        { key: "join_date", sortable: true },
        { label: "Salary", key: "salary_gross", sortable: true },
        { key: "actions", sortable: true },
      ],
    };
  },
  validations: {
    employee: {
      user: {
        email: {
          required,
          email,
        },
      },
      salary_gross: { required, numeric },
      salary_net: { required, numeric },
      salary_social: { required, numeric },
      salary_social_company: { required, numeric },
      salary_social_employee: { required, numeric },
      salary_taxes: { required, numeric },
    },
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.employees.length;
    },
  },
  watch: {},
  mounted() {
    // Set the initial number of items
    this.totalRows = this.employees.length;
  },
  methods: {
    /**
     * Search the table data with search input
     */
    ...userMethods,
    ...employeeMethods,
    async toggleStatus(row) {
      const employee = row.item;
      if (this.updating.includes(parseInt(employee.id))) return;
      this.updating.push(parseInt(employee.id));
      const res = await this.createEmployee({
        ...employee,
        is_active: !employee.is_active,
      });
      if (res.success) {
        await this.getEmployees();
        this.updating.splice(this.updating.indexOf(parseInt(employee.id)), 1);
      }
    },
    addBtnClicked(e) {
      e.preventDefault();
      this.employee = {
        ...EMPLOYEE,
      };
      this.$bvModal.show("employees-modal");
    },
    updateWorkPlan(e) {
      console.log(e);
      this.employee.work_plan = null;
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async onDeleteEmployee(row) {
      return await this.deleteEmployee(row.item.id);
    },
    onRowSelected(row) {
      this.employee = {
        ...row.item,
        user: {
          ...row.item.user,
          profile: row.item.user.profile ?? {},
        },
        reporting_to: this.employees.find(
          (e) => parseInt(e.id) === parseInt(row.item.employee_id)
        ),
        department: this.departments.find(
          (e) => parseInt(e.id) === parseInt(row.item.department_id)
        ),
        job_title: this.job_titles.find(
          (e) => parseInt(e.id) === parseInt(row.item.job_title_id)
        ),
        work_plan_type: this.work_plans.find(
          (e) => parseInt(e.id) === parseInt(row.item.work_plan_id)
        )?.type,
        work_plan: this.work_plans.find(
          (e) => parseInt(e.id) === parseInt(row.item.work_plan_id)
        ),
      };
      console.log(this.employee);
      this.$bvModal.show("employees-modal");
    },
    async checkUserByEmail() {
      if (this.$v.employee?.user?.email.$invalid) {
        return false;
      }
      try {
        const res = await this.getUserByEmail({
          email: this.employee.user.email,
        });
        if (res.success) {
          this.employee.user = {
            profile: {},
            ...res.data,
          };
          return true;
        }
        return false;
      } catch (error) {
        return false;
      }
    },
    async submitEmployee() {
      if (this.loading) return;
      this.$v.$touch();
      console.log(this.$v.$invalid);
      console.log(this.employee);
      console.log(this.$v);

      try {
        const newEmployee = {
          ...this.employee,
          employee_id: this.employee.reporting_to?.id ?? null,
          department_id: this.employee.department?.id,
          job_title_id: this.employee.job_title?.id,
          work_plan_id: this.employee.work_plan?.id ?? null,
        };
        this.loading = true;
        const res = await this.createEmployee(newEmployee);
        if (res.success) {
          this.$bvModal.hide("employees-modal");
          this.getEmployees();
          this.loading = false;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async calculateSalary() {
      try {
        let requestBody = {
          decimal_points: 2,
          net_salary: 0,
          gross_salary: 0,
        };
        if (this.employee.salary_net_salary) {
          requestBody.net_salary = this.employee.salary_net;
        } else {
          requestBody.gross_salary = this.employee.salary_gross;
        }
        const res = await fetch(
          `${process.env.VUE_APP_HRMS_API_URL}/get_salary_details`,
          {
            method: "post",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(requestBody),
          }
        );
        const json = await res.json();
        this.employee.salary_gross = json.gross;
        this.employee.salary_net = json.net;
        this.employee.salary_social = json.basic;
        this.employee.salary_social_employee = json.social_insurance?.employee;
        this.employee.salary_social_company = json.social_insurance?.company;
        this.employee.salary_taxes = json.taxes;
      } catch (error) {
        console.log("ERROR", error);
      }
    },
  },
};
</script>

<template>
  <b-row>
    <b-col md="12">
      <b-card>
        <b-card-body>
          <b-row>
            <b-col md="6">
              <div id="tickets-table_length" class="dataTables_length">
                <label class="d-inline-flex align-items-center">
                  Show&nbsp;
                  <b-form-select
                    v-model="perPage"
                    size="sm"
                    :options="pageOptions"
                  ></b-form-select
                  >&nbsp;entries
                </label>
              </div>
            </b-col>
            <!-- Search -->
            <b-col md="6">
              <div
                id="tickets-table_filter"
                class="dataTables_filter text-md-right"
              >
                <label class="d-inline-flex align-items-center">
                  Search:
                  <b-form-input
                    v-model="filter"
                    type="search"
                    placeholder="Search..."
                    class="form-control form-control-sm ml-2"
                  ></b-form-input>
                </label>
                <b-button
                  variant="success"
                  size="sm"
                  class="float-right mx-2 mb-2 spinner"
                  :disabled="loading"
                  @click="addBtnClicked"
                >
                  <i v-if="!loading" class="mdi mdi-plus"></i>
                  <b-spinner small v-if="loading" />
                </b-button>
              </div>
            </b-col>
            <!-- End search -->
          </b-row>
          <!-- Table -->
          <div class="table-responsive mb-0">
            <b-table
              hover
              :items="employees"
              :fields="fields"
              responsive="sm"
              :per-page="perPage"
              :current-page="currentPage"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
            >
              <template #cell(is_active)="row">
                <div
                  @click="toggleStatus(row)"
                  class="text-center d-flex align-items-start"
                >
                  <b-spinner
                    v-if="updating.includes(parseInt(row.item.id))"
                    class="mx-2 mt-2"
                    small
                    variant="dark"
                  ></b-spinner>
                  <switches
                    :disabled="updating.includes(parseInt(row.item.id))"
                    class="mb-0 mt-2"
                    v-model="row.value"
                    type-bold="true"
                  ></switches>
                </div>
              </template>
              <template #cell(name)="row">
                <a :href="`/profile/${row.item.id}`">{{
                  row.item.user?.profile
                    ? `${row.item.user.profile.first_name} ${row.item.user.profile.last_name}`
                    : row.item.user.name
                }}</a>
              </template>
              <template #cell(job_title_id)="row">
                {{
                  job_titles.find(
                    (jt) => parseInt(jt.id) === parseInt(row.value)
                  )?.name ?? ""
                }}
              </template>
              <template v-slot:cell(actions)="row">
                <b-button
                  size="sm"
                  variant="ghost"
                  v-b-tooltip.hover
                  data-toggle="tooltip"
                  title="Edit"
                  @click="onRowSelected(row)"
                >
                  <i class="mdi mdi-pencil font-size-18"></i>
                </b-button>
                <confirm-delete
                  icon
                  :callback="async () => onDeleteEmployee(row)"
                />
              </template>
            </b-table>
          </div>
          <b-row>
            <div class="col">
              <div
                class="dataTables_paginate paging_simple_numbers float-right"
              >
                <ul class="pagination pagination-rounded mb-0">
                  <!-- pagination -->
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="rows"
                    :per-page="perPage"
                  ></b-pagination>
                </ul>
              </div>
            </div>
          </b-row>
        </b-card-body>
      </b-card>
    </b-col>
    <b-modal
      id="employees-modal"
      size="lg"
      centered
      title="new Employee"
      title-class="text-capitalize"
      hide-footer
    >
      <b-spinner v-if="loading" class="float-right" variant="primary" />
      <form-wizard @on-complete="submitEmployee" color="#556ee6">
        <tab-content
          v-if="!employee.id"
          icon="mdi mdi-email"
          title="User"
          :before-change="checkUserByEmail"
        >
          <b-row class="justify-content-center">
            <b-col md="6">
              <b-form-group>
                <label for="email">Email</label>
                <input
                  id="email"
                  type="text"
                  class="form-control"
                  v-model="employee.user.email"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </tab-content>
        <tab-content icon="mdi mdi-account-circle" title="Basic Info">
          <b-row>
            <b-col md="4">
              <b-form-group>
                <label for="first_name">First name</label>
                <input
                  id="first_name"
                  type="text"
                  class="form-control"
                  name="first_name"
                  v-model="employee.user.profile.first_name"
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group>
                <label for="last_name">Last name</label>
                <input
                  id="last_name"
                  type="text"
                  class="form-control"
                  name="last_name"
                  v-model="employee.user.profile.last_name"
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group>
                <label for="other_name">Name in Arabic</label>
                <input
                  id="other_name"
                  type="text"
                  class="form-control"
                  name="other_name"
                  v-model="employee.user.profile.other_name"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="4">
              <b-form-group>
                <label for="work_email">Work email</label>
                <input
                  id="work_email"
                  type="email"
                  class="form-control"
                  name="work_email"
                  v-model="employee.email"
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group>
                <label for="phone_number">Phone number</label>
                <input
                  id="phone_number"
                  type="text"
                  class="form-control"
                  name="phone_number"
                  v-model="employee.user.profile.mobile"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </tab-content>
        <tab-content icon="mdi mdi-face-profile" title="Personal Info">
          <b-row>
            <b-col md="4">
              <b-form-group>
                <label for="birth_date">Birth date</label>
                <input
                  id="birth_date"
                  type="date"
                  class="form-control"
                  name="birth_date"
                  v-model="employee.user.profile.birth_date"
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group>
                <label for="gender">Gender</label>
                <b-form-select
                  id="gender"
                  name="gender"
                  v-model="employee.user.profile.gender"
                >
                  <b-form-select-option value="male">Male</b-form-select-option>
                  <b-form-select-option value="female"
                    >Female</b-form-select-option
                  >
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group>
                <label for="marital_status">Marital status</label>
                <b-form-select
                  id="marital_status"
                  name="marital_status"
                  v-model="employee.user.profile.marital_status"
                >
                  <b-form-select-option value="single"
                    >Single</b-form-select-option
                  >
                  <b-form-select-option value="married"
                    >Married</b-form-select-option
                  >
                  <b-form-select-option value="divorced"
                    >Divorced</b-form-select-option
                  >
                  <b-form-select-option value="widowed"
                    >Widowed</b-form-select-option
                  >
                </b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="4">
              <b-form-group>
                <label for="number_of_kids">Number of Kids</label>
                <input
                  id="number_of_kids"
                  min="0"
                  type="number"
                  class="form-control"
                  name="number_of_kids"
                  v-model="employee.user.profile.number_of_kids"
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group>
                <label for="religion">Religion</label>
                <b-form-select
                  id="religion"
                  name="religion"
                  v-model="employee.user.profile.religion"
                >
                  <b-form-select-option value="muslim"
                    >Muslim</b-form-select-option
                  >
                  <b-form-select-option value="christian"
                    >Christian</b-form-select-option
                  >
                  <b-form-select-option value="jewish"
                    >Jewish</b-form-select-option
                  >
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group>
                <label for="for_label">Nationality</label>
                <input
                  id="first_name"
                  type="text"
                  class="form-control"
                  name="first_name"
                  v-model="employee.user.profile.nationality"
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group>
                <label for="personal_email">Personal email</label>
                <input
                  type="email"
                  class="form-control"
                  :value="employee.user.email"
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group>
                <label for="for_label">Emergency Contact Name</label>
                <input
                  id="first_name"
                  type="text"
                  class="form-control"
                  name="first_name"
                  v-model="employee.emergency_contact_name"
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group>
                <label for="for_label">Emergency Contact Number</label>
                <input
                  id="last_name"
                  type="text"
                  class="form-control"
                  name="last_name"
                  v-model="employee.emergency_contact_number"
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group>
                <label for="address">Address</label>
                <b-form-textarea
                  id="address"
                  name="address"
                  v-model="employee.address"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </tab-content>
        <tab-content icon="mdi mdi-briefcase" title="Work Info">
          <b-row>
            <b-col md="4">
              <b-form-group>
                <label for="joining_date">Joining date</label>
                <input
                  id="joining_date"
                  type="date"
                  class="form-control"
                  name="joining_date"
                  v-model="employee.join_date"
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group>
                <label for="reporting_to">Reporting to</label>
                <multiselect
                  v-model="employee.reporting_to"
                  :options="employees"
                  :searchable="true"
                  :allow-empty="true"
                  label="name"
                  track-by="id"
                >
                  <template slot="singleLabel" slot-scope="{ option }">
                    {{ option.user?.profile?.first_name ?? option.user?.name }}
                    {{ option.user?.profile?.last_name }}
                  </template>
                  <template slot="option" slot-scope="{ option }">
                    {{ option.user?.profile?.first_name ?? option.user?.name }}
                    {{ option.user?.profile?.last_name }}
                  </template>
                </multiselect>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group>
                <label for="department">Department</label>
                <multiselect
                  v-model="employee.department"
                  :options="departments"
                  :searchable="true"
                  :allow-empty="false"
                  label="name"
                  track-by="id"
                ></multiselect>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="4">
              <b-form-group>
                <label for="job_title">Job title</label>
                <multiselect
                  v-model="employee.job_title"
                  :options="job_titles"
                  :searchable="true"
                  :allow-empty="false"
                  label="name"
                  track-by="id"
                ></multiselect>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group>
                <label for="contract_type">Contract type</label>
                <b-form-select v-model="employee.contract_type">
                  <b-form-select-option value="full-time"
                    >Full Time</b-form-select-option
                  >
                  <b-form-select-option value="part-time"
                    >Part Time</b-form-select-option
                  >
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group>
                <label for="contract_start_date">Contract start date</label>
                <input
                  id="contract_start_date"
                  type="date"
                  class="form-control"
                  name="contract_start"
                  v-model="employee.contract_start"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="4">
              <b-form-group>
                <label for="contract_end">Contract end date</label>
                <input
                  id="contract_end"
                  type="date"
                  class="form-control"
                  name="contract_end"
                  v-model="employee.contract_end"
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group>
                <label for="probation_date">Probation date</label>
                <input
                  id="probation_date"
                  type="date"
                  class="form-control"
                  name="probation_date"
                  v-model="employee.probation_date"
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group>
                <label for="work_plan_type">Work plan Type</label>
                {{ employee.work_plan_type }}
                <b-form-select
                  v-model="employee.work_plan_type"
                  @change="updateWorkPlan($event)"
                >
                  <b-form-select-option value="daily"
                    >Daily</b-form-select-option
                  >
                  <b-form-select-option value="task">Task</b-form-select-option>
                  <b-form-select-option value="shift"
                    >Shift</b-form-select-option
                  >
                </b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="4">
              <b-form-group>
                <label for="work_plan_id">Work Plan Name</label>
                <multiselect
                  v-model="employee.work_plan"
                  :options="
                    work_plans.filter((p) => p.type === employee.work_plan_type)
                  "
                  :searchable="true"
                  :allow-empty="true"
                  label="name"
                  track-by="id"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </tab-content>
        <tab-content
          icon="mdi mdi-checkbox-marked-circle-outline"
          title="Salary Info"
        >
          <b-row>
            <b-col md="4">
              <b-form-group>
                <label for="salary">
                  {{ employee.salary_net_salary ? "Net" : "Gross" }}
                  Salary</label
                >
                <b-form-checkbox v-model="employee.salary_net_salary" switch>
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group>
                <label for="salary"
                  >Salary (<span class="text-muted"
                    >{{ employee.job_title?.min_salary }} -
                    {{ employee.job_title?.max_salary }}</span
                  >)</label
                >
                <b-form-input
                  type="number"
                  min="0"
                  v-if="!employee.salary_net_salary"
                  v-model="employee.salary_gross"
                  :class="{
                    'is-invalid': $v.employee.salary_gross.$error,
                  }"
                />
                <b-form-feedback>
                  <span v-if="!$v.employee.salary_gross.required"
                    >This value is required.</span
                  >
                </b-form-feedback>

                <b-form-input
                  type="number"
                  v-if="employee.salary_net_salary"
                  v-model="employee.salary_net"
                />
              </b-form-group>
            </b-col>
            <b-col md="4" align-self="end">
              <div class="form-group">
                <b-button variant="primary" @click="calculateSalary"
                  >Calculate</b-button
                >
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="4">
              <b-form-group disabled>
                <label for="salary">Gross salary</label>
                <b-form-input
                  name="gross"
                  type="number"
                  step="any"
                  v-model="employee.salary_gross"
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group>
                <label for="salary">Net salary</label>
                <b-form-input
                  name="net"
                  type="number"
                  step="any"
                  v-model="employee.salary_net"
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group>
                <label for="tax">Tax</label>
                <b-form-input
                  name="taxes"
                  type="number"
                  step="any"
                  v-model="employee.salary_taxes"
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group>
                <label for="social_insurance">Social insurance</label>
                <b-form-input
                  name="social"
                  type="number"
                  step="any"
                  v-model="employee.salary_social"
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group>
                <label for="employee_share">Employee share</label>
                <b-form-input
                  name="social_employee"
                  type="number"
                  step="any"
                  v-model="employee.salary_social_employee"
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group>
                <label for="company_share">Company share</label>
                <b-form-input
                  name="social_company"
                  type="number"
                  step="any"
                  v-model="employee.salary_social_company"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </tab-content>
      </form-wizard>
    </b-modal>
  </b-row>
</template>