<script>
import _ from "lodash";
import Multiselect from "vue-multiselect";

import { required } from "vuelidate/lib/validators";
import { departmentMethods } from "../../../state/helpers";
import ConfirmDelete from "@/components/confirm-delete";

/**
 * Departments component
 */
export default {
  props: {
    employees: {
      default: [],
      required: true,
    },
    departments: {
      default: [],
      required: true,
    },
  },
  components: {
    Multiselect,
    ConfirmDelete,
  },
  data() {
    return {
      submitted: false,
      department: {},
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: false,
      fields: [
        { key: "name", sortable: true },
        { key: "leader", sortable: true },
        { key: "parent", sortable: true },
        { key: "actions", sortable: true },
      ],
    };
  },
  validations: {
    department: {
      name: { required },
      manager: { required },
      parent: {},
      note: {},
    },
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.departments.length;
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.departments.length;
  },
  methods: {
    ...departmentMethods,
    async onDeleteDepartment({ item: { id } }) {
      return await this.deleteDepartment(id);
    },
    async formSubmit(e) {
      e.preventDefault();
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) return;
      const newDepartment = {
        id: this.department.id ?? null,
        department_id: this.department.parent?.id ?? null,
        name: this.department.name,
        employee_id: this.department.manager?.id,
        note: this.department.note ?? null,
      };
      const res = await this.createDepartment(newDepartment);
      if (res.success) {
        this.$v.$reset();
        this.department = {};
        this.getDepartments();
        this.$bvModal.hide("departments-modal");
      }
    },
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    onRowSelected(row) {
      const selectedDepartment = row.item;
      this.department = {
        ...selectedDepartment,
        manager: this.employees.find(
          (e) => parseInt(e.id) === parseInt(selectedDepartment.employee_id)
        ),
        parent: this.departments.find(
          (d) => parseInt(d.id) === parseInt(selectedDepartment.department_id)
        ),
      };
      console.log(this.department, selectedDepartment);
      this.$bvModal.show("departments-modal");
    },
    getEmployeeFromId(id) {
      const employee = _.find(this.employees, { id: parseInt(id) });
      if (employee) {
        let name = employee.user.name;
        if (employee.user.profile) {
          name = _.get(employee, "user.profile.first_name");
          name += " ";
          name += _.get(employee, "user.profile.last_name");
        }
        return name;
      }
    },
  },
};
</script>

<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <b-button
            v-b-modal.departments-modal
            variant="success"
            size="sm"
            class="float-right mb-2"
          >
            <i class="mdi mdi-plus"></i>
          </b-button>
          <div class="row">
            <div class="col-sm-12 col-md-6">
              <div id="tickets-table_length" class="dataTables_length">
                <label class="d-inline-flex align-items-center">
                  Show&nbsp;
                  <b-form-select
                    v-model="perPage"
                    size="sm"
                    :options="pageOptions"
                  ></b-form-select
                  >&nbsp;entries
                </label>
              </div>
            </div>
            <!-- Search -->
            <div class="col-sm-12 col-md-6">
              <div
                id="tickets-table_filter"
                class="dataTables_filter text-md-right"
              >
                <label class="d-inline-flex align-items-center">
                  Search:
                  <b-form-input
                    v-model="filter"
                    type="search"
                    placeholder="Search..."
                    class="form-control form-control-sm ml-2"
                  ></b-form-input>
                </label>
              </div>
            </div>
            <!-- End search -->
          </div>
          <!-- Table -->
          <div class="table-responsive mb-0">
            <b-table
              hover
              striped
              :items="departments"
              :fields="fields"
              responsive="sm"
              :per-page="perPage"
              :current-page="currentPage"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
            >
              <template #cell()="row">
                <div class="text-nowrap">
                  {{ row.value || "---" }}
                </div>
              </template>
              <template v-slot:cell(actions)="row">
                <b-button
                  v-b-tooltip.hover
                  data-toggle="tooltip"
                  title="Edit"
                  variant="ghost"
                  @click="onRowSelected(row)"
                >
                  <i class="mdi mdi-pencil font-size-18"></i>
                </b-button>
                <confirm-delete
                  icon
                  :callback="() => onDeleteDepartment(row)"
                />
              </template>
              <template #cell(parent)="row">
                <div class="text-nowrap">
                  {{
                    departments.find(
                      (d) => parseInt(d.id) === parseInt(row.item.department_id)
                    )?.name ?? "---"
                  }}
                </div>
              </template>
              <template #cell(leader)="row">
                <div class="text-nowrap">
                  {{ getEmployeeFromId(row.item.employee_id) ?? "---" }}
                </div>
              </template>
            </b-table>
          </div>
          <div class="row">
            <div class="col">
              <div
                class="dataTables_paginate paging_simple_numbers float-right"
              >
                <ul class="pagination pagination-rounded mb-0">
                  <!-- pagination -->
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="rows"
                    :per-page="perPage"
                  ></b-pagination>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      id="departments-modal"
      centered
      title="New Department"
      title-class="font-18 text-capitalize"
      hide-footer
    >
      <form
        class="needs-validation form-horizontal"
        role="form"
        @submit.prevent="formSubmit"
      >
        <b-form-group
          label-cols-lg="4"
          label="Parent Department"
          label-for="parent"
        >
          <multiselect
            v-model="department.parent"
            :options="departments"
            :searchable="true"
            :allow-empty="true"
            label="name"
            track-by="id"
            :class="{
              'is-invalid': submitted && $v.department.parent.$error,
            }"
          />
          <div
            v-if="submitted && $v.department.parent.$error"
            class="invalid-feedback"
          >
            <span v-if="!$v.department.parent.required"
              >This value is required.</span
            >
          </div>
        </b-form-group>
        <b-form-group label-cols-lg="4" label="Name" label-for="name">
          <b-form-input
            v-model="department.name"
            type="text"
            name="name"
            :class="{
              'is-invalid': submitted && $v.department.name.$error,
            }"
          />
          <div
            v-if="submitted && $v.department.name.$error"
            class="invalid-feedback"
          >
            <span v-if="!$v.department.name.required"
              >This value is required.</span
            >
          </div>
        </b-form-group>
        <b-form-group
          label-cols-lg="4"
          label="Department Leader"
          label-for="leader"
        >
          <multiselect
            v-model="department.manager"
            :options="employees"
            :searchable="true"
            track-by="id"
            :class="{
              'is-invalid': submitted && $v.department.manager.$error,
            }"
          >
            <template slot="singleLabel" slot-scope="{ option }">
              {{ option.user?.profile?.first_name ?? option.user?.name }}
              {{ option.user?.profile?.last_name }}
            </template>
            <template slot="option" slot-scope="{ option }">
              {{ option.user?.profile?.first_name ?? option.user?.name }}
              {{ option.user?.profile?.last_name }}
            </template>
          </multiselect>
          <div
            v-if="submitted && $v.department.manager.$error"
            class="invalid-feedback"
          >
            <span v-if="!$v.department.manager.required"
              >This value is required.</span
            >
          </div>
        </b-form-group>
        <!-- <b-form-group label-cols-lg="4" label="Note" label-for="note">
          <b-form-textarea name="note" v-model="department.note" />
        </b-form-group> -->
        <div class="text-right">
          <b-button type="submit" variant="primary">Submit</b-button>
        </div>
      </form>
    </b-modal>
  </div>
</template>